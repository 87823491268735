<template>
    <div v-if="currentUser.superAdmin">
        <v-data-table
                :headers="headers"
                :items="banners"
                :options.sync="pagination"
                :server-items-length="totalBanners"
                :loading="loading"
                :footer-props="{
        'showFirstLastPage':true,
      'items-per-page-options': [10, 20, 50, 100]
    }"
                class="elevation-1"
        >
            <template v-slot:item.shopId="{ item }">
                <td class="text-xs-right">
                    {{ item.bannerDraft.shop != null ? item.bannerDraft.shop.id : "" }}
                </td>
            </template>
            <template v-slot:item.shopName="{ item }">
                <td class="text-xs-right">
                    {{ item.bannerDraft.shop != null ? item.bannerDraft.shop.name : "" }}
                </td>
            </template>
            <template v-slot:item.bannerDraft="{ item }">
                <td class="text-xs-right">
                    {{ item.bannerDraft.id }}
                </td>
            </template>
            <template v-slot:item.bannerPicture="{ item }">
                <td class="text-xs-right">
                    <v-img
                            :src="item.bannerDraft.media.fullThumbPath1"
                            contain
                            max-height="100"
                            max-width="100"
                            @error="
            item.bannerDraft.media.fullThumbPath1 = 'media/error/imageNotFound.png'
          "
                    ></v-img>
                </td>
            </template>
            <template v-slot:item.bannerMessage="{ item }">
                <td class="text-xs-right">
                    {{ item.bannerDraft.text }}
                </td>
            </template>
            <template v-slot:item.city="{ item }">
                <td class="text-xs-right">
                    {{ item.city.name }}
                </td>
            </template>
            <template v-slot:item.category="{ item }">
                <td class="text-xs-right">
                    {{ item.shopCategory.name }}
                </td>
            </template>
            <template v-slot:item.published="{ item }">
                <v-switch
                        v-model="item.published"
                        @click="publishBanner(item.id, item.published)"
                ></v-switch>
            </template>

            <template v-slot:body.prepend>
                <tr>
                    <td>
                        <v-spacer></v-spacer>
                    </td>
                    <td>
                        <v-text-field v-model="shopName" type="text"
                                      @keyup.enter="page = 1;retrieveBanners();"></v-text-field>
                    </td>
                    <td>
                        <v-spacer></v-spacer>
                    </td>
                    <td>
                        <v-menu
                                ref="dateRangeMenu"
                                v-model="dateRangeMenu"
                                :close-on-content-click="false"
                                :return-value.sync="dates"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        v-model="dates"
                                        v-bind="attrs"
                                        v-on="on"
                                        clearable
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dates" range>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dateRangeMenu = false">
                                    Cancel
                                </v-btn>
                                <v-btn
                                        text
                                        color="primary"
                                        @click="
                  $refs.dateRangeMenu.save(dates);
                  retrieveBanners();
                "
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </td>
                    <td>
                        <v-spacer></v-spacer>
                    </td>
                    <td>
                        <v-text-field v-model="bannerMessage" type="text"
                                      @keyup.enter="page = 1;retrieveBanners();"></v-text-field>
                    </td>
                    <td>
                        <v-text-field v-model="cityName" type="text"
                                      @keyup.enter="page = 1;retrieveBanners();"></v-text-field>
                    </td>
                    <td>
                        <v-text-field v-model="categoryName" type="text"
                                      @keyup.enter="page = 1;retrieveBanners();"></v-text-field>
                    </td>
                    <td>
                        <v-spacer></v-spacer>
                    </td>
                    <td>
                        <v-row>
                            <v-col>
                                <v-select
                                        :items="bannerPublishedList"
                                        v-model="bannerPublished"
                                        dense
                                        hide-details
                                        @keyup.enter="page = 1;retrieveBanners();"
                                ></v-select
                                >
                            </v-col>

                            <v-col>
                                <v-avatar color="indigo" size="30">
                                    <v-icon
                                            dark
                                            @click="
                    page = 1;
                    retrieveBanners();
                  "
                                    >mdi-magnify
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col>
                                <v-avatar color="grey" size="30">
                                    <v-icon
                                            dark
                                            @click="
                    page = 1;
                    clearSearch();
                  "
                                    >mdi-close
                                    </v-icon>
                                </v-avatar>
                            </v-col>
                        </v-row>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <div class="table-footer-prepend d-flex pl-2 align-center">
            <v-btn
                    :loading="downloadingReport"
                    :disabled="downloadingReport"
                    color="green darken-1"
                    class="ma-2 white--text"
                    @click="
              loader = 'downloadingReport';
              downloadReport();
            "
            >
                Banners Report
                <v-icon right dark>
                    mdi-file-excel
                </v-icon>
            </v-btn>
            <v-btn
                    :loading="downloadingTotalReport"
                    :disabled="downloadingTotalReport"
                    color="green darken-1"
                    class="ma-2 white--text"
                    @click="
              loader = 'downloadingTotalReport';
              downloadTotalReport();
            "
            >
                Banners Total Report
                <v-icon right dark>
                    mdi-file-excel
                </v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
    import ApiService from "@/core/services/api.service";
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import {mapGetters} from "vuex";

    export default {
        name : "banners" ,
        computed : {
          ...mapGetters(["currentUser"]),
            name() {
                return this.$i18n.t ( 'menu.banners' )
            } ,
            headers() {
                return [
                    {
                        text : this.$i18n.t('commonTable.shopId') ,
                        align : "start" ,
                        sortable : false ,
                        value : "shopId" ,
                        width : "7%"
                    } ,
                    { text : this.$i18n.t('commonTable.shopName') , value : "shopName" , sortable : false } ,
                    { text : this.$i18n.t('pages.banners.bannerId') , value : "bannerDraft" , width : "10%" } ,
                    { text : this.$i18n.t('commonTable.date') , value : "date" } ,
                    { text : this.$i18n.t('pages.banners.bannerPicture') , value : "bannerPicture" , sortable : false } ,
                    { text : this.$i18n.t('pages.banners.bannerText') , value : "bannerMessage" , sortable : false } ,
                    { text : this.$i18n.t('commonTable.city') , value : "city" , sortable : false } ,
                    { text : this.$i18n.t('commonTable.category') , value : "category" , sortable : false } ,
                    { text : this.$i18n.t('commonTable.total') , value : "price" , width : "10%" } ,
                    { text : this.$i18n.t('commonTable.published') , value : "published" , width : "15%" , sortable : false }
                ]
            }
        } ,
        data() {
            return {
                dates : [] ,
                dateRangeMenu : false ,
                shopName : "" ,
                bannerMessage : "" ,
                cityName : "" ,
                categoryName : "" ,
                bannerPublished : "" ,
                totalBanners : 0 ,
                banners : [] ,
                loading : true ,
                pagination : {} ,
                bannerPublishedList : ["All" , "No" , "Yes"] ,
                downloadingReport : false ,
                downloadingTotalReport : false ,
                errors : []
            };
        } ,
        watch : {
            pagination : {
                handler() {
                    this.retrieveBanners ();
                } ,
                deep : true
            }
        } ,
        mounted() {
            this.$store.dispatch ( SET_BREADCRUMB , [{ title : `${this.name}`}] );
        } ,
        methods : {
            downloadReport() {
                this.downloadingReport = true;
                const params = this.getRequestParams (
                    this.shopName ,
                    this.dates ,
                    this.bannerMessage ,
                    this.cityName ,
                    this.categoryName ,
                    this.published
                );
                params.responseType = "blob";
                ApiService.getFileWithParams ( `/api/download/excel/banners-report` , params ).then ( response => {
                    const blob = new Blob ( [response.data] , {
                        type : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    } );
                    const link = document.createElement ( "a" );
                    link.href = URL.createObjectURL ( blob );
                    link.download = "Banners";
                    link.click ();
                    URL.revokeObjectURL ( link.href );
                } )
                    .catch ( error => {
                        this.$log.error ( "Error: " , error );
                        this.errored = true;
                    } )
                    .finally ( () => (this.downloadingReport = false) );
            } ,
            downloadTotalReport() {
                this.downloadingTotalReport = true;
                const params = this.getRequestParams (
                    this.shopName ,
                    this.dates ,
                    this.bannerMessage ,
                    this.cityName ,
                    this.categoryName ,
                    this.published
                );
                params.responseType = "blob";
                ApiService.getFileWithParams ( `/api/download/excel/banners-total-report` , params ).then ( response => {
                    const blob = new Blob ( [response.data] , {
                        type : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    } );
                    const link = document.createElement ( "a" );
                    link.href = URL.createObjectURL ( blob );
                    link.download = "BannersTotal";
                    link.click ();
                    URL.revokeObjectURL ( link.href );
                } )
                    .catch ( error => {
                        this.$log.error ( "Error: " , error );
                        this.errored = true;
                    } )
                    .finally ( () => (this.downloadingTotalReport = false) );
            } ,
            publishBanner(id , publish) {
                this.loading = true;
                ApiService.patch ( `api/banners/${id}` , {
                    published : publish
                } )
                    .then ( response => {
                        this.$log.debug ( "Published: " , response.data.published );
                        this.info = response.data.published;
                        this.retrieveBanners ();
                    } )
                    .catch ( error => {
                        this.$log.error ( "Error: " , error );
                        this.errored = true;
                    } )
                    .finally ( () => (this.loading = false) );
            } ,
            getRequestParams(
                shopName ,
                dates ,
                bannerMessage ,
                cityName ,
                categoryName ,
                published
            ) {
                let params = {};

                if (shopName) {
                    params["shopName"] = shopName;
                }

                if (dates != null && dates[0] != null) {
                    params["dateRange"] = dates.join ( "," );
                }

                if (bannerMessage) {
                    params["bannerMessage"] = bannerMessage;
                }

                if (cityName) {
                    params["cityName"] = cityName;
                }

                if (categoryName) {
                    params["categoryName"] = categoryName;
                }

                if (published) {
                    params["published"] = published;
                }

                let sort;
                let sortBy = this.pagination.sortBy;
                let sortDesc = this.pagination.sortDesc;
                if (sortBy.length === 1 && sortDesc.length === 1) {
                    sort = sortBy[0];
                    if (sortDesc[0] === true) sort += ",desc";
                }
                params["sort"] = sort;
                params["page"] = this.pagination.page;
                params["size"] = this.pagination.itemsPerPage;

                return params;
            } ,
            clearSearch() {
                (this.shopName = ""),
                    (this.bannerMessage = ""),
                    (this.cityName = ""),
                    (this.categoryName = ""),
                    (this.published = ""),
                    (this.dates = []);
            } ,
            retrieveBanners() {
                const params = this.getRequestParams (
                    this.shopName ,
                    this.dates ,
                    this.bannerMessage ,
                    this.cityName ,
                    this.categoryName ,
                    this.published
                );

                return new Promise ( resolve => {
                    this.loading = true;
                    ApiService.query ( "api/banners" , {
                        params
                    } ).then ( response => {
                        // JSON responses are automatically parsed.
                        this.$log.debug ( "Banners: " , response.data.content );
                        this.banners = response.data.content;
                        this.totalBanners = response.data.totalElements;
                        this.totalPages = response.data.totalPages;
                        this.loading = false;
                        let title = this.totalBanners;
                        this.$store.dispatch ( SET_BREADCRUMB , [{ title : `${this.name}: ` + title }] );
                        resolve ();
                    } );
                } );
            }
        }
    };
</script>

<style>
    .v-data-table.v-data-table.v-data-table >>> td {
        font-size: 16px;
    }

    .v-icon.v-icon {
        font-size: 24px !important;
    }

    tbody tr:nth-of-type(even) {
        background-color: rgba(236, 237, 237);
    }

    tbody tr:nth-of-type(odd) {
        background-color: rgb(250, 250, 250);
    }
</style>
